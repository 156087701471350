<h1 mat-dialog-title>Stop the music</h1>
<div mat-dialog-content>
  Are you sure you want to stop the music? This may affect your playlist mix
  when resuming.
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button mat-dialog-close cdkFocusInitial (click)="stop()">
    Yes
  </button>
</div>
