<div mat-dialog-content>
  <h3>There is currently nothing to play!  </h3>
  <div>1. Please ensure that your DJ has set playlists for this time of day.
  </div>
  <div>2. Check your computer's time and date settings and ensure they are correct for your local time.
  </div>
  <div>3.Refresh the page and try again.
  </div>
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>
