import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, firstValueFrom} from 'rxjs';
import {MoodMetadata} from '../interfaces/MoodMetadata';
import {env} from 'process';
import {APP_CONFIG} from '../../../environments/environment';
import {IVenueInfo} from '../interfaces/IVenueInfo';
import {DebugPlayLog} from "../interfaces/Playlog";

@Injectable({
  providedIn: 'root',
})
export class MoodPlayerService {
  constructor(private http: HttpClient) {
  }

  PLAYER_SERVICE_API = `${APP_CONFIG.apiUrl}/api/Player`;
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
  };

  getPlaylistMetadata(): Observable<MoodMetadata> {
    return this.http.get<MoodMetadata>(
      `${this.PLAYER_SERVICE_API}/GetMetadata`,
      {
        responseType: 'json',
        headers: {
          'X-Priority': 'High',
        },
      }
    );
  }

  async getSongById(songId: number): Promise<Blob> {
    return await firstValueFrom(
      this.http.get(`${this.PLAYER_SERVICE_API}/GetSong?songId=${songId}`, {
        responseType: 'blob',
        headers: {
          'X-Priority': 'High',
        },
      })
    );
  }

  getVenueInfo(): Observable<IVenueInfo> {
    return this.http.get<IVenueInfo>(`${this.PLAYER_SERVICE_API}/GetVenueInfo`);
  }

  updateVenueInfo(info: IVenueInfo): Observable<IVenueInfo> {
    return this.http.post<IVenueInfo>(
      `${this.PLAYER_SERVICE_API}/UpdateVenue`,
      JSON.stringify(info),
      this.httpOptions
    );
  }

  uploadDebugPlayLogs(logs: DebugPlayLog) {
    return this.http.post(`${this.PLAYER_SERVICE_API}/SaveDebugPlayLog`, logs, this.httpOptions);
  }
}
