import { Component } from '@angular/core';
import { PlaylistPlayerService } from '../core/services/playlist-player.service';

@Component({
  selector: 'app-stop-music-popup',
  templateUrl: './stop-music-popup.component.html',
  styleUrls: ['./stop-music-popup.component.scss'],
})
export class StopMusicPopupComponent {
  constructor(private playlistPlayerService: PlaylistPlayerService) {}
  stop() {
    this.playlistPlayerService.Stop();
    this.playlistPlayerService.NotifyToStop();
  }
}
