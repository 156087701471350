import {MoodMetadata} from "./MoodMetadata";
import {Song} from "./Song";

export class DebugPlayLog {
  id: string;
  SongA: string;
  SongB: string;
  moodMetadata: string;
  index: number;
  playlist: string;
  isMixing: boolean;
  isProcessingNextSong: boolean;
  isFadingOut: boolean;
  timeOfTheEvent: string;
  activePlayer: "A" | "B";
  overlappingPlayer: "A" | "B";


  constructor(
    moodMetadata: MoodMetadata,
    SongA: Song | null | undefined,
    SongB: Song | null | undefined,
    index: number,
    playlist: Song[],
    isMixing: boolean,
    isProcessingNextSong: boolean,
    isFadingOut: boolean,
    timeOfTheEvent: string,
    playerPlayingFirst: "A" | "B",
    playerOverlapping: "A" | "B",
  ) {
    this.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    this.overlappingPlayer = playerOverlapping;
    this.SongB = JSON.stringify(SongB);
    this.moodMetadata = JSON.stringify(moodMetadata);
    this.SongA = JSON.stringify(SongA);
    this.index = index;
    this.playlist = JSON.stringify(playlist);
    this.isMixing = isMixing;
    this.isProcessingNextSong = isProcessingNextSong;
    this.isFadingOut = isFadingOut;
    this.timeOfTheEvent = timeOfTheEvent;
    this.activePlayer = playerPlayingFirst;
  }
}
