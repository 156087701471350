import { Injectable } from '@angular/core';
import { Song } from '../interfaces/Song';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SongList } from '../interfaces/SongList';

@Injectable({
  providedIn: 'root',
})
export class MusicService {
  constructor(private http: HttpClient) {}
}
