import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IVenueInfo } from '../core/interfaces/IVenueInfo';
import { MoodPlayerService } from '../core/services/mood-player.service';

@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss'],
})
export class EditProfileDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IVenueInfo,
    private moodPlayerService: MoodPlayerService
  ) {}
  updateInfo() {
    this.moodPlayerService.updateVenueInfo(this.data).subscribe((info) => {
      console.log(info);
    });
  }
}
