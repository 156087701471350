<div
  style="
    width: 300px;
    padding: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
  "
>
  <div style="width: 100%">
    <div
      (click)="onEditProfile()"
      style="
        display: flex;
        align-items: flex-end;
        justify-content: end;
        margin-bottom: 25px;
      "
    >
      <fa-icon
        class="button"
        style="color: #c4c4c4; font-size: 22px"
        [icon]="faPenToSquare"
      ></fa-icon>
    </div>
  </div>
  <div style="align-items: center; display: flex; flex-direction: column">
    <div
      style="
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background-color: #dfdfdf;
      "
    >
      <fa-icon
        class="button"
        style="color: #a9a9a9; font-size: 24px"
        [icon]="faUser"
      ></fa-icon>
    </div>
    <div
      style="
        margin-top: 15px;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
      "
    >
      <div style="font-size: medium; font-weight: 700; color: #2a2a2a">
        {{ venueInfo.name == "V" ? "Kollaborative" : venueInfo.name }}
      </div>
      <div style="font-size: small; color: #bababa">
        {{ venueInfo.country }}
      </div>
    </div>
    <div></div>
  </div>

  <div
    style="
      align-items: start;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    "
  >
    <div
      style="
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
      "
    >
      <fa-icon
        class="button"
        style="color: #b9b9b9; font-size: 15px"
        [icon]="faUser"
      ></fa-icon>
      <div style="margin-left: 4px; font-size: small; color: #2a2a2a">
        {{ venueInfo.contactName }}
      </div>
    </div>

    <div
      style="
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
      "
    >
      <fa-icon
        class="button"
        style="color: #b9b9b9; font-size: 15px"
        [icon]="faPhone"
      ></fa-icon>
      <div style="margin-left: 4px; font-size: small; color: #2a2a2a">
        {{ venueInfo.phoneNumber }}
      </div>
    </div>
    <div
      style="
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
      "
    >
      <fa-icon
        class="button"
        style="color: #b9b9b9; font-size: 15px"
        [icon]="faGlobe"
      ></fa-icon>
      <div style="margin-left: 4px; font-size: small; color: #2a2a2a">
        {{ venueInfo.timeZone }}
      </div>
    </div>
  </div>
  <div
    style="
      height: 100px;
      width: 250px;
      border-top: #d8d8d8 1px solid;
      align-items: center;
      justify-content: center;
      display: flex;
    "
  >
    <button mat-button (click)="onLogOut()">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </div>
</div>
