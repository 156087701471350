<mat-sidenav-container class="container">
  <mat-sidenav-content>
    <div style="
        display: flex;
        flex-direction: row;
        width: 100vw;
        background-color: #fff;
        justify-content: space-between;
      ">
      <div style="
          margin-top: 10px;
          width: 100vw;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        ">
        <img [src]="assetPath + '/logo.svg'" style="width: 150px; height: 50px; margin-left: 20px" />

        <div style="margin-right: 20px">
          <div style="display: flex; flex-direction: row; align-items: center">
            <button style="
                margin-right: 20px;
                background-color: #efefef;
                border-radius: 35px;
                border-color: transparent;
                padding: 15px;
              ">
              <a style="text-decoration: none; color: #282828" target="_blank" href="https://www.groovit.io/faqs-1">Need
                help?</a>
            </button>
            <button style="
                background-color: rgb(239, 239, 239);
                align-items: center;
                justify-content: center;
                display: flex;
              " mat-icon-button (click)="openProfile()" aria-label="Example icon-button with a menu">
              <fa-icon class="button" style="color: #b9b9b9; font-size: 32px" [icon]="faUser"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      ">
      <div style="
          width: 400px;
          height: 360px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
        ">
        <img class="pulse" *ngIf="isPlaying" [src]="assetPath + '/repeatedPulse.gif'" alt="logo" />
        <div class="svg-container" [ngClass]="{ pulse: isPulsing }">
          <img [src]="assetPath + '/silver-speaker.png'" style="width: 350px" />
        </div>

        <button mat-fab class="play-button" (click)="play()" *ngIf="!isPlaying">
          <mat-spinner diameter="30" *ngIf="
              DownloadingFirstSlot || (isPlayProcessActive && !isShortWait)
            "></mat-spinner>
          <fa-icon *ngIf="
              !DownloadingFirstSlot && !isPlayProcessActive && !isShortWait
            " class="button" style="color: #2f2f2f; font-size: 32px" [icon]="faPlay"></fa-icon>
        </button>

        <button mat-fab class="stop-button" (click)="stop()" *ngIf="isPlaying || isShortWait">
          <fa-icon *ngIf="isPlaying || isShortWait" class="button" style="color: #2f2f2f; font-size: 32px"
            [icon]="faStop"></fa-icon>
        </button>
      </div>

      <div class="menu">
        <div style="
            display: flex;
            height: 5%;
            width: 100%;
            background-color: #d1d1d1;
          ">
          <div class="progress-bar">
            <mat-progress-bar mode="determinate" value="{{ currentTime }}"></mat-progress-bar>
          </div>
        </div>
        <div style="
            display: flex;
            flex-direction: row;
            height: 95%;
            width: 95%;
            align-items: center;
          ">
          <div class="sound-slider">
            <img class="customIconSize" [src]="assetPath + '/logo_ico.png'" alt="logo" class="customIconSize"
              style="width: 40px; height: 40px; margin-right: 25px" />
            <mat-slider style="width: 100px" min="0" max="100" step="1" showTickMarks discrete
              [displayWith]="formatLabel">
              <input matSliderThumb value="{{ songVolume }}" (change)="setSongVolume($event)" />
            </mat-slider>
          </div>
          <div class="song-info">
            <div class="song-name">
              <div *ngIf="
                  isShortWait === false &&
                  (!activeSong || activeSong?.title?.length === 0)
                " style="
                  color: #282828;
                  font-weight: 600;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-wrap: nowrap;
                ">
                {{ activeSong?.title ?? "Welcome" }}
              </div>
              <div *ngIf="isShortWait === true" style="
                  font-size: 16px;
                  color: #282828;
                  font-weight: 400;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-wrap: nowrap;
                ">
                {{ "Starting soon" }}
              </div>
              <div *ngIf="isShortWait" class="scroll">
                <div class="m-scroll">
                  <span>Your playlist will start at {{ shortWaitTime }}</span><span>Your playlist will start at {{
                    shortWaitTime }}</span><span>Your playlist will start at {{ shortWaitTime }}</span><span>Your
                    playlist will start at {{ shortWaitTime }}</span>
                </div>
              </div>
              <div *ngIf="
                  activeSong &&
                  activeSong.title.length <= 15 &&
                  activeSong.title.length > 0
                " style="
                  color: #282828;
                  font-weight: 600;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-wrap: nowrap;
                ">
                {{ activeSong?.title ?? "Welcome" }}
              </div>
              <div *ngIf="activeSong && activeSong.title.length > 15" class="scroll">
                <div class="m-scroll">
                  <span> {{ activeSong.title }}</span><span> {{ activeSong.title }}</span><span> {{ activeSong.title
                    }}</span><span> {{ activeSong.title }}</span>
                </div>
              </div>
              <div style="
                  color: #282828;
                  text-overflow: ellipsis;
                  overflow: hidden;
                ">
                {{ activeSong?.artist ?? "" }}
              </div>
            </div>
          </div>
          <div class="action-controller">
            <!-- <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: #e2e2e2;
                border-radius: 40px;
                height: 34px;
                width: 109px;
              "
            >
              <div class="like-container" (click)="toggleLike()">
                <img
                  src="../../assets/like-hand.png"
                  alt="like"
                  [@likeAnimation]="likeState"
                  [ngClass]="{
                    selectedLikedIcon: isLiked,
                    unselectedLikedIcon: !isLiked
                  }"
                />
              </div>
              <div class="dislike-container" (click)="toggleDislike()">
                <img
                  src="../../assets/like-hand.png"
                  alt="dislike"
                  [@likeAnimation]="likeState"
                  [ngClass]="{
                    selectedDislikeIcon: isDisliked,
                    unselectedDislikeIcon: !isDisliked
                  }"
                />
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div *ngIf="(percentageOfInitialSlot<0.95 && percentageOfInitialSlot>0.05) || (percentageOfAllSongs<95&&percentageOfAllSongs>5)" style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
        ">
        <div *ngIf="DownloadingFirstSlot" style="color: #080808; font-size: 12px;          margin-bottom: 10px;
">
          {{ downloadingStatus }}
        </div>

        <mat-progress-bar *ngIf="DownloadingFirstSlot" diameter="20" mode="determinate" style="width: 60%;"
          value="{{ percentageOfInitialSlot * 100 }}"></mat-progress-bar>

        <div *ngIf="!DownloadingFirstSlot&& percentageOfAllSongs<95&&percentageOfAllSongs>5" style="color: #080808; font-size: 12px;          margin-bottom: 10px;
">
          {{ downloadingAllStatus }}
        </div>
        <mat-progress-bar *ngIf="!DownloadingFirstSlot && percentageOfAllSongs<95&&percentageOfAllSongs>5" diameter="20"
          style="width: 60%;" mode="determinate" value="{{ percentageOfAllSongs  }}"></mat-progress-bar>
      </div>
    </div>
  </mat-sidenav-content>
  <mat-sidenav mode="over" position="end" [(opened)]="isProfileOpen" #sidenav>
    <app-profile [isProfileOpen]="isProfileOpen"> </app-profile>
  </mat-sidenav>
</mat-sidenav-container>