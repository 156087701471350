import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HomeModule } from './home/home.module';
import { DetailModule } from './detail/detail.module';

import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './core/services/http-interceptor';
import { CommonModule } from '@angular/common';
import { LoginModule } from './login/login.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifyPopupComponent } from './notify-popup/notify-popup.component';
import { StopMusicPopupComponent } from './stop-music-popup/stop-music-popup.component';
import { ProfileComponent } from './profile/profile.component';
import { EditProfileDialogComponent } from './edit-profile-dialog/edit-profile-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StoragePopupComponent } from './storage-popup/storage-popup.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { APP_CONFIG } from '../environments/environment';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

// AoT requires an exported function for factories
export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

// Default ErrorHandler to be used in non-production environments
class DefaultErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error('An error occurred:', error);
  }
}

// Providers for error handling and Sentry integration
const sentryErrorHandlerProvider: Provider = {
  provide: ErrorHandler,
  useFactory: () => (APP_CONFIG.production ? Sentry.createErrorHandler() : new DefaultErrorHandler()),
};

const sentryTraceServiceProvider: Provider = {
  provide: Sentry.TraceService,
  useFactory: (router: Router) => (APP_CONFIG.production ? new Sentry.TraceService(router) : null),
  deps: [Router],
};

const appInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (traceService: Sentry.TraceService) => (APP_CONFIG.production ? () => {} : () => null),
  deps: [Sentry.TraceService],
  multi: true,
};

@NgModule({
  declarations: [
    AppComponent,
    NotifyPopupComponent,
    StopMusicPopupComponent,
    EditProfileDialogComponent,
    StoragePopupComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    LoginModule,
    ForgotPasswordModule,
    HomeModule,
    DetailModule,
    AppRoutingModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    GoogleTagManagerModule.forRoot({
      id: APP_CONFIG.googleTagManagerId || null,
    }),
  ],
  providers: [
    httpInterceptorProviders,
    sentryErrorHandlerProvider,
    sentryTraceServiceProvider,
    appInitializerProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
