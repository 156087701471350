<div class="container">
  <div
    style="
      background-color: white;
      display: flex;
      flex-direction: row;
      padding: 50px;
      box-shadow: 0 4px 30px rgb(224, 224, 224);
    "
  >
    <div style="display: flex; align-items: center; margin-right: 30px">
      <img
        class="customIconSize"
        [src]="assetPath + '/moodincWebHome.gif'"
        alt="logo"
        class="customIconSize"
        style="width: 250px; height: 250px"
      />
    </div>
    <div style="width: 30vw; min-width: 300px">
      <div style="display: flex; flex-direction: column">
        <label
          mat-label
          style="font-size: x-large; font-weight: 600; color: rgb(0, 0, 0)"
          >Groovit</label
        >
        <label
          mat-label
          style="
            font-size: small;
            margin-top: 10px;
            margin-bottom: 10px;
            color: rgb(0, 0, 0);
          "
          >Email address</label
        >
        <mat-form-field appearance="fill">
          <mat-label>e-mail</mat-label>
          <input
            required
            ngModel
            email
            [(ngModel)]="userEmail"
            (keydown.enter)="onLogInAction($event)"
            matInput
            placeholder="customer@groovit.com"
          />
          <mat-icon matSuffix>alternate_email</mat-icon>
        </mat-form-field>

        <label
          mat-label
          style="
            color: rgb(0, 0, 0);
            font-size: small;

            margin-bottom: 10px;
          "
          >Password</label
        >
        <mat-form-field appearance="fill">
          <mat-label>password</mat-label>
          <input
            required
            type="password"
            [(ngModel)]="userPassword"
            (keydown.enter)="onLogInAction($event)"
            matInput
          />
          <mat-icon matSuffix>vpn_key</mat-icon>
        </mat-form-field>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
        "
      >
        <div style="color: tomato" *ngIf="invalidLogin">Invalid Login</div>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          "
        >
          <button
            *ngIf="!invalidLogin"
            style="width: 150px"
            mat-raised-button
            color="primary"
            (click)="onLogInAction($event)"
          >
            Login
          </button>
          <mat-spinner
            *ngIf="isLoggingIn"
            style="margin-left: 10px; width: 25px; height: 25px"
            color="#ffff"
            diameter="30"
          ></mat-spinner>
        </div>
        <button
          *ngIf="invalidLogin"
          style="width: 150px"
          mat-raised-button
          color="primary"
          (click)="onLogInAction($event)"
        >
          Try Again
        </button>

        <button
          style="color: rgb(0, 0, 0); font-size: small"
          mat-button
          (click)="forgotPassword()"
        >
          Forgot Password
        </button>
      </div>
    </div>
  </div>
</div>
