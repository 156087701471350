import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { PageNotFoundComponent } from './components/';
import { WebviewDirective } from './directives/';
import { FormsModule } from '@angular/forms';
import { WaveSurferService } from '../core/services/wave-surfer.service';


@NgModule({
  declarations: [PageNotFoundComponent, WebviewDirective],
  imports: [TranslateModule, CommonModule, FormsModule],
  exports: [TranslateModule, WebviewDirective, FormsModule],
  providers: [WaveSurferService],

})
export class SharedModule {}
