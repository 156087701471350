/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { MoodMetadata } from '../interfaces/MoodMetadata';
import { openDB, IDBPDatabase } from 'idb';
import { SongDB } from '../interfaces/SongDB';
import { ElectronService } from './electron/electron.service';
import { Buffer } from 'buffer';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private db: IDBPDatabase<SongDB> | null = null;
  private lastSize = 0;
  constructor(private electronService: ElectronService) {
    void this.initDatabase();
  }

  async disposeAnyObsoleteSongs(futureIds: number[]): Promise<number> {
    if (this.lastSize === 0) {
      console.log('skipping cleaning because song db is empty');
      return -1;
    }
    const transaction = this.db!.transaction('audioStore', 'readwrite');
    const store = transaction.objectStore('audioStore');
    if (futureIds.length === 0) {
      await store.clear();
      console.log(' cleaning everything because no future songs');

      this.lastSize = 0;
      return -1;
    }
    const storedKeys = await store.getAll();

    let totalDeleted = 0;

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    storedKeys.forEach(async (key) => {
      if (!futureIds.includes(+key.id)) {
        const index = storedKeys.indexOf(key);
        if (index > -1) {
          await store.delete(key.id);
          totalDeleted++;
        }
      }
    });
    return totalDeleted;
  }

  async initDatabase() {
    this.db = await openDB<SongDB>('myDatabase', 1, {
      upgrade(db) {
        const store = db.createObjectStore('audioStore', { keyPath: 'id' });
        store.createIndex('id', 'id');
      },
    });
  }

  async getAudio(id: string): Promise<Blob | undefined> {
    if (this.electronService.isElectron) {
      // Request data from Electron's main process
      const buff = await this.electronService.readAudioFile(id);
      const mimeType = 'audio/mpeg'; // Replace with the actual MIME type of your audio
      const audio = this.bufferToAudioBlob(buff, mimeType);
      return audio;
    } else {
      // Use IndexedDB
      const transaction = this.db!.transaction('audioStore', 'readonly');
      const store = transaction.objectStore('audioStore');
      return (await store.get(id))?.audioBlob;
    }
  }

  async storeAudio(id: string, endDatetime: Date, audioBlob: Blob) {
    if (this.electronService.isElectron) {
      // Send data to Electron's main process
      console.log('saving blob to electron');
      const buffer = Buffer.from(await audioBlob.arrayBuffer());
      await this.electronService.writeAudioFile(id, buffer);
    } else {
      // Use IndexedDB
      console.log('saving blob to indexeddb');
      this.lastSize++;
      const transaction = this.db!.transaction('audioStore', 'readwrite');
      const store = transaction.objectStore('audioStore');
      await store.put({ id, endDatetime, audioBlob });
    }
  }

  async clean() {
    this.lastSize = 0;
    const transaction = this.db!.transaction('audioStore', 'readwrite');
    const store = transaction.objectStore('audioStore');
    await store.clear();
    // this.db!.close();
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  public setUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public removeUser(): void {
    window.sessionStorage.removeItem(USER_KEY);
  }
  private getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  getToken(): string {
    const user = this.getUser();
    return user.accessToken;
  }

  getTokenExpiry(): number {
    const user = this.getUser();
    return user.expiresIn as number;
  }

  getRefreshToken(): string {
    const user = this.getUser();
    return user.refreshToken;
  }

  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }

    return false;
  }

  setMetadata(metadata: MoodMetadata): void {
    window.localStorage.setItem('metadata', JSON.stringify(metadata));
  }

  getMetadata(): MoodMetadata {
    const metadata = window.localStorage.getItem('metadata');
    if (metadata) {
      return JSON.parse(metadata) as MoodMetadata;
    }

    return undefined as unknown as MoodMetadata;
  }
  bufferToAudioBlob(buffer: ArrayBuffer, mimeType = 'audio/mpeg'): Blob {
    return new Blob([buffer], { type: mimeType });
  }
}
