export interface IPlaylistStatus {
  startingTime: Date;
  status: PlaylistStatus;
}
export enum PlaylistStatus {
  playing,
  not_ready,
  not_found,
  synchronizing,
  paused,
  long_wait,
  short_wait,
  stopped,
}
