<div style="width: 50vw">
  <h1 mat-dialog-title>Edit profile details</h1>
  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column">
      <mat-form-field>
        <mat-label>Contact name</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="data.contactName" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone number</mat-label>
        <input matInput placeholder="Phone" [(ngModel)]="data.phoneNumber" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
      <button
        (click)="updateInfo()"
        color="primary"
        mat-button
        mat-dialog-close
        cdkFocusInitial
      >
        Save
      </button>
    </div>
  </div>
</div>
