import { Injectable } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import { StorageService } from './storage.service';

@Injectable()
export class WaveSurferService {
  public waveSurfer: WaveSurfer | null = null;

  interval = 5;
  fadeAudio: number | null = null;
  currentTime = 0;

  constructor(private storageService: StorageService) {}

  loadAudio(url: string): void {
    this.waveSurfer?.load(url).catch(() => {
      console.log('Error loading audio');
    });
  }
  play(): void {
    if (this.waveSurfer) {
      this.waveSurfer.play().catch((e) => {
        console.log('Error playing audio');
        console.log(e);
      });
    }
  }
  playPause(): void {
    this.waveSurfer?.playPause().catch(() => {
      console.log('Error playing/pausing audio');
    });
  }

  isPlaying() {
    return this.waveSurfer?.isPlaying();
  }

  getWaveSurfer(
    container: string,
    songUrl: string,
    peaks: any,
    duration: number
  ): WaveSurfer {
    this.waveSurfer?.setOptions({
      container: container,
      waveColor: 'white',
      cursorColor: 'yellow',
      barHeight: 0.5,
      height: 70,
      url: songUrl,
      peaks: peaks,
      duration: duration,
      fetchParams: {
        headers: {
          Authorization: 'Bearer ' + this.storageService.getToken(),
        },
      },
    });
    this.waveSurfer?.load(songUrl, [[0]]).catch(() => {
      console.log('Error loading audio');
    });
    return this.waveSurfer!;
  }

  stop() {
    if (this.fadeAudio) {
      clearInterval(this.fadeAudio);
    }
    this.waveSurfer?.stop();
  }

  setTime(currentTime: number) {
    this.currentTime = currentTime;
  }
  getCurrentTime() {
    return this.currentTime;
  }

  setVolume(arg0: number) {
    this.waveSurfer?.setVolume(arg0);
  }

  seekTo(arg0: number) {
    this.waveSurfer!.setTime(arg0);
  }

  load(
    songUrl: string,
    peaks: (Float32Array | number[])[] | undefined,
    duration: number
  ) {
    this.waveSurfer!.load(songUrl, peaks).catch(() => {
      console.log('Error loading audio');
    });
  }

  pause() {
    this.waveSurfer!.pause();
  }
  getVolume() {
    if (!this.waveSurfer) {
      return 0;
    }
    return this.waveSurfer.getVolume();
  }

  fadeIn(duration: number, targetVolume: number) {
    if (this.fadeAudio) {
      cancelAnimationFrame(this.fadeAudio);
    }
    if (!this.waveSurfer) {
      return;
    }

    this.waveSurfer.setVolume(0);
    this.waveSurfer.play().catch(() => {
      console.log('Error playing audio');
    });

    const step = 0.01;
    const interval = 1000 / 30; // Limit to 30 FPS
    const startTime = performance.now();
    let volume = 0;

    const fade = (timestamp: number) => {
      const elapsedTime = timestamp - startTime;
      let progress = elapsedTime / duration;
      progress = Math.min(1, Math.max(0, progress)); // Clamp progress between 0 and 1

      volume = progress * targetVolume;
      this.waveSurfer!.setVolume(volume);

      if (progress < 1) {
        this.fadeAudio = requestAnimationFrame(fade);
      }
    };

    this.fadeAudio = requestAnimationFrame(fade);
  }

  fadeOut(duration: number, targetVolume: number) {
    if (this.fadeAudio) {
      cancelAnimationFrame(this.fadeAudio);
    }
    if (!this.waveSurfer) {
      return;
    }

    const step = 0.01;
    const interval = 1000 / 30; // Limit to 30 FPS
    const startTime = performance.now();
    const initialVolume = this.waveSurfer.getVolume();

    const fade = (timestamp: number) => {
      const elapsedTime = timestamp - startTime;
      let progress = elapsedTime / duration;
      progress = Math.min(1, Math.max(0, progress)); // Clamp progress between 0 and 1

      const volume = initialVolume - progress * initialVolume;
      this.waveSurfer!.setVolume(volume);

      if (progress < 1) {
        this.fadeAudio = requestAnimationFrame(fade);
      } else {
        this.waveSurfer!.pause();
      }
    };

    this.fadeAudio = requestAnimationFrame(fade);
  }

  cancelFade() {
    if (this.fadeAudio) {
      cancelAnimationFrame(this.fadeAudio);
    }
  }
}
