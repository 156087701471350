import { Component } from '@angular/core';

@Component({
  selector: 'app-notify-popup',
  templateUrl: './notify-popup.component.html',
  styleUrls: ['./notify-popup.component.scss']
})
export class NotifyPopupComponent {

}
