import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { StorageService } from '../core/services/storage.service';
import { Subject, takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ElectronService } from '../core/services/electron/electron.service';
import { APP_CONFIG } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export default class LoginComponent implements OnInit, OnDestroy {
  returnUrl: any;
  private destroy$ = new Subject<void>();
  invalidLogin = false;
  loggingIn = false;
  invalidInput = false;
  userEmail = '';
  userPassword = '';
  isLoggingIn = false;
  assetPath = '';
  constructor(
    private electronService: ElectronService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storageService: StorageService,
    private _snackBar: MatSnackBar
  ) {
    this.loggingIn = false;
    this.invalidLogin = false;
  }

  ngOnInit(): void {
    this.assetPath = APP_CONFIG.production ? 'assets' : 'assets';

    this.loadCredentials();

    console.log('LoginComponent INIT');
  }
  setUserEmail(event: Event) {
    if (event && event.target && (event.target as HTMLTextAreaElement).value)
      this.userEmail = (event.target as HTMLTextAreaElement).value;
  }
  setUserPassword(event: KeyboardEvent) {
    if (event && event.key?.toLocaleLowerCase() === 'enter') {
      this.onLogInAction(event);
      return;
    }
    if (event && event.target && (event.target as HTMLTextAreaElement).value)
      this.userPassword = (event.target as HTMLTextAreaElement).value;
  }
  loadCredentials(): void {
    if (!this.electronService.isElectron) return;
    this.electronService
      .loadCredentials()
      .then(({ decryptedEmail, decryptedPassword }) => {
        console.log('Credentials loaded');
        console.log(decryptedEmail);
        this.userEmail = decryptedEmail;
        this.userPassword = decryptedPassword;
        // Optionally auto-login the user or fill the login form
      })
      .catch((err) => console.error(err));
  }
  onLogInAction(e: Event) {
    console.log('LoginComponent onLogInAction');
    this.isLoggingIn = true;
    this.authService
      .login(this.userEmail, this.userPassword)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          console.log('data --> onLoggedInACtion');
          console.log(data);
          this.storageService.setUser(data);
          console.log('data --> onLoggedInACtion', this.userEmail);
          if (this.electronService.isElectron) {
            this.electronService
              .saveCredentials(this.userEmail, this.userPassword)
              .then(() => {
                console.log('Credentials saved');
              })
              .catch((err) => console.error(err));
          }
          localStorage.setItem('isLoggedin', 'true');
          if (localStorage.getItem('isLoggedin')) {
            {
              console.log('LoginComponent onLoggedInACtion');
              this.router.navigate(['/home']);
              this._snackBar.open("Let's play some music!! 📢", 'Close', {
                duration: 3000,
              });
            }
          }
        },
        (error) => {
          this.isLoggingIn = false;

          console.log('error --> onLoggedInACtion');
          if (error.status === 401) {
            this.invalidLogin = true;
          }
        },
        () => {
          this.isLoggingIn = false;
        }
      );
  }
  forgotPassword() {
    console.log('LoginComponent forgotPassword');
    this.router.navigate(['/forgot-password']);
  }

  onTryAgain(): void {
    this.invalidLogin = false;
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
