/* eslint-disable @typescript-eslint/no-misused-promises */
import { Component, Input, OnInit } from '@angular/core';
import { MoodPlayerService } from '../core/services/mood-player.service';
import { IVenueInfo } from '../core/interfaces/IVenueInfo';
import { Router } from '@angular/router';
import { StorageService } from '../core/services/storage.service';
import { faUser, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { EditProfileDialogComponent } from '../edit-profile-dialog/edit-profile-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PlaylistPlayerService } from '../core/services/playlist-player.service';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() isProfileOpen = false;

  venueInfo: IVenueInfo = {} as IVenueInfo;
  faUser = faUser;
  faPhone = faPhone;
  faGlobe = faGlobe;
  faPenToSquare = faPenToSquare;

  constructor(
    private moodPlayerService: MoodPlayerService,
    private router: Router,
    private storageService: StorageService,
    public dialog: MatDialog,
    private playlistPlayerService: PlaylistPlayerService,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    this.moodPlayerService.getVenueInfo().subscribe((info) => {
      console.log(info);
      this.venueInfo = info;
    });
  }

  async goToPlayer() {
    await this.router.navigate(['/home']);
  }
  onEditProfile() {
    this.dialog.open(EditProfileDialogComponent, { data: this.venueInfo });
  }
  async logout() {
    await this.storageService.clean();
    localStorage.removeItem('isLoggedin');
    this.playlistPlayerService.Stop();
    this.playlistPlayerService.NotifyToStop();
    this.dialog.closeAll();
    await this.router.navigate(['/login']).catch(() => {});
  }
  onLogOut() {
    console.log('HomeComponent onLogOut');
    this.authService.logout().subscribe(
      async (response) => {
        console.log('logout response', response);
        await this.logout();
      },
      async (error) => {
        console.error('Error logging out', error);
        await this.logout();
      }
    );
  }
}
