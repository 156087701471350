/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
import * as path from 'path';

// If you import a module but never use any of the imported values other than as TypeScript types,
// the resulting javascript file will look as if you never imported the module at all.
import { ipcRenderer, webFrame } from 'electron';
import * as childProcess from 'child_process';
import * as fs from 'fs';

@Injectable({
  providedIn: 'root',
})
export class ElectronService {
  ipcRenderer!: typeof ipcRenderer;
  webFrame!: typeof webFrame;
  childProcess!: typeof childProcess;
  fs!: typeof fs;
  contextBridge: any;

  constructor() {
    // Conditional imports
    if (this.isElectron) {
      this.ipcRenderer = (window as any).require('electron').ipcRenderer;
      this.webFrame = (window as any).require('electron').webFrame;
      //  this.contextBridge = (window as any).require('electron').contentBridge;
      this.fs = (window as any).require('fs');

      this.childProcess = (window as any).require('child_process');
      this.childProcess.exec('node -v', (error, stdout, stderr) => {
        if (error) {
          console.error(`error: ${error.message}`);
          return;
        }
        if (stderr) {
          console.error(`stderr: ${stderr}`);
          return;
        }
        console.log(`stdout:\n${stdout}`);
      });

      // Notes :
      // * A NodeJS's dependency imported with 'window.require' MUST BE present in `dependencies` of both `app/package.json`
      // and `package.json (root folder)` in order to make it work here in Electron's Renderer process (src folder)
      // because it will loaded at runtime by Electron.
      // * A NodeJS's dependency imported with TS module import (ex: import { Dropbox } from 'dropbox') CAN only be present
      // in `dependencies` of `package.json (root folder)` because it is loaded during build phase and does not need to be
      // in the final bundle. Reminder : only if not used in Electron's Main process (app folder)

      // If you want to use a NodeJS 3rd party deps in Renderer process,
      // ipcRenderer.invoke can serve many common use cases.
      // https://www.electronjs.org/docs/latest/api/ipc-renderer#ipcrendererinvokechannel-args
    }
  }

  get isElectron(): boolean {
    return !!(window && window.process && window.process.type);
  }
  directoryPath = path.join('data/audio/', 'audio');
  // const userDataPath = (electron.app || electron.remote.app).getPath('userData');
  // const directoryPath = path.join(userDataPath, 'data');

  writeAudioFile(id: string, data: Buffer): Promise<void> {
    // Absolute path to the audio directory
    const filePath = path.join(this.directoryPath, `${id}`); // Include the file extension if needed

    return new Promise((resolve, reject) => {
      // Create the directory
      this.fs.mkdir(this.directoryPath, { recursive: true }, (dirErr) => {
        if (dirErr) {
          console.error('Error creating directory:', dirErr);
          reject(dirErr);
          return;
        }

        // Write the file
        this.fs.writeFile(filePath, data, (fileErr) => {
          if (fileErr) {
            console.error('Error writing file:', fileErr);
            reject(fileErr);
          } else {
            console.log('File written successfully to', filePath);
            resolve();
          }
        });
      });
    });
  }
  readAudioFile(id: string): Promise<Buffer> {
    const filePath = path.join(`${this.directoryPath}/${id}`);
    return new Promise((resolve, reject) => {
      this.fs.readFile(filePath, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  saveCredentials(email: string, password: string): Promise<void> {
    console.log('saveCredentials');
    return this.ipcRenderer
      .invoke('encrypt-credentials', { email, password })
      .then(({ encryptedEmail, encryptedPassword }) => {
        localStorage.setItem('userEmail', encryptedEmail);
        localStorage.setItem('userPassword', encryptedPassword);
      });
  }

  async loadCredentials(): Promise<{
    decryptedEmail: string;
    decryptedPassword: string;
  }> {
    const encryptedEmail = localStorage.getItem('userEmail');
    const encryptedPassword = localStorage.getItem('userPassword');
    console.log('loadCredentials');
    console.log(encryptedEmail);
    if (encryptedEmail && encryptedPassword) {
      return this.ipcRenderer.invoke('decrypt-credentials', {
        encryptedEmail,
        encryptedPassword,
      });
    }

    throw new Error('No stored credentials');
  }
}
