import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APP_CONFIG } from '../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  userEmail = '';
  assetPath = '';
  constructor(
    private router: Router,
    private authService: AuthService,
    private _snackBar: MatSnackBar
  ) {
    this.assetPath = APP_CONFIG.production ? 'assets' : '../../assets';
  }

  setUserEmail(event: Event) {
    if (event && event.target && (event.target as HTMLTextAreaElement).value)
      this.userEmail = (event.target as HTMLTextAreaElement).value;
  }
  onSubmit() {
    console.log('ForgotPasswordComponent onSubmit');
    this.authService.forgotPasswordLink(this.userEmail).subscribe({
      next: (data) => {
        console.log(data);
        this._snackBar
          .open('Check your email for the reset link', 'Close', {
            duration: 3000,
          })
          .afterDismissed()
          .subscribe(() => {
            this.router.navigate(['/login']);
          });
      },
      error: (e) => {
        console.log(e);
        this._snackBar.open(
          'Oops! Unable to send a reset mail. Try again in a bit.',
          'Close',
          {
            duration: 5000,
          }
        );
      },
      complete: () => {
        console.log('complete');
      },
    });
  }

  onBack() {
    console.log('ForgotPasswordComponent onBack');
    this.router.navigate(['/login']);
  }
}
